import React from "react"
import cloud from "../../assets/images/services-icon/cloud.png"
import { Link } from "gatsby"
import * as Icon from "react-feather"
import { StaticImage } from "gatsby-plugin-image"

const MainBanner = () => {
  return (
    <>
      <div className="gcp-main-banner ptb-80">
        <div className="icon">
          <img src={cloud} />
        </div>
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              {/* <div className="gcp-banner-content"> */}
              <h2>Google Cloud Platform</h2>
              <p>
                Atyeti is strategically positioned to ensure that you get the
                most of your Google Cloud Platform investment. As a GCP Partner,
                our services encompass advisory, robust infrastructure and
                platform deployment, secure workloads, comprehensive application
                migration support as well enable you to take data-driven
                decisions leveraging the power of Google Cloud.
              </p>
              <Link to="/google-cloud-service" className="learn-more-btn">
                <Icon.ArrowRight /> Learn More
              </Link>
              {/* </div> */}
            </div>
            <div className="col-lg-7 col-md-12">
              <StaticImage
                src="../../assets/images/gcp-transparent-img.png"
                alt="gcp"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default MainBanner
