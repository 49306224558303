import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import MainBanner from "../components/Home/MainBanner"
import WaysToManage from "../components/Home/WaysToManage"
import DiscoverArea from "../components/Home/DiscoverArea"
import Services from "../components/Home/Services"
import NewsletterStyleTwo from "../components/Home/NewsletterStyleTwo"
import Footer from "../components/_App/Footer"
import Partner from "../components/Shared/Partner"
import Gcp from "../components/Home/Gcp"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Welcome to Atyeti"
      description="Atyeti provides Technology Services that transform and enhance your Digital ecosystem."
      keyword="atyeti, exceeding expectations, digital, digital transformation, technology services, digital platform, it services"
    />
    <Navbar />
    <MainBanner />
    <Services />
    <Gcp/>
    <DiscoverArea />
    {/* <WaysToManage /> */}
    <Partner />
    <NewsletterStyleTwo />
    <Footer />
  </Layout>
)

export default IndexPage
