import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import buildTeams from "../../assets/icons/noun_team build_2176769.svg"
import globalDelivery from "../../assets/icons/noun_Global Delivery_4171201.svg"
import centerOfExcellence from "../../assets/icons/noun_Excellence_2218235.svg"

const data = [
  {
    id: 1,
    name: "Build Teams",
    para: `We have deep expertise to   help you navigate a rapidly changing
  digital landscape.`,
    icon: buildTeams,
  },
  {
    id: 2,
    name: "Global Delivery",
    para: `Provide technical expertise and structured processes from locations worldwide.`,
    icon: globalDelivery,
  },
  {
    id: 3,
    name: "Centers of Excellence",
    para: `Our COE’s are a shared capability that provide leadership and
  best practices, in our focus areas so you can take full
  advantage of our capability.`,
    icon: centerOfExcellence,
  },
]
const DiscoverArea = () => {
  return (
    <div className="discover-area">

      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-5 col-sm-12">
            <div className="discover-image">
              <StaticImage
                src="../../assets/images/bull.jpeg"
                alt="WHY ATYETI"
              />
            </div>
          </div>

          <div className="col-lg-7 col-sm-12">
            <div className="discover-content">
              <h2>WHY ATYETI</h2>
              <h3>
                Atyeti helps enterprises succeed in driving digital
                transformation
              </h3>
              <p>
                When adopting new technologies, it is easy for organizations to
                get lost searching for the right solutions, needing to upskill
                technical teams, changing its software development culture, and
                driving change.
              </p>
              <p>
                Before you know it, your team can be distracted by these new
                projects, not delivering on your organization's core business
                value. Atyeti allows your organization to focus on what it does
                best—helping you build and maintain your digital infrastructure.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="analytics-shape1">
        {/* <StaticImage
          src="../../assets/images/bigdata-analytics/analytics-shape1.png"
          alt="WHY ATYETI"
        /> */}
      </div>

      <div className="banner-boxes-area ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="different-design"></div>
            {data.map(item => (
              <div key={item.id} className="col-lg-4 col-sm-6">
                <div className="single-banner-boxes">
                  <div className="icon">
                    <img src={item.icon} alt="box icon" />
                  </div>
                  <h3>{item.name}</h3>
                  <p>{item.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiscoverArea
