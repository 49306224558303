import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const NewsletterStyleTwo = () => {
  return (
      <div className="newsletter-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 col-sm-12 ">
              <div className="newsletter-image">
                <StaticImage
                  src="../../assets/images/pre-footer.png"
                  alt="Are you ready for the next step in your career"
                />
              </div>
            </div>

            <div className="col-lg-7  col-sm-12">
              <div className="newsletter-content">
                <div className="newsletter-form d-md-flex align-items-center">
                  <h2>Are you ready to take the next step in your career?</h2>
                  <Link to="/careers">
                    <button> Join Us!</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NewsletterStyleTwo
