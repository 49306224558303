import React from "react"
import Finastra from "../../assets/images/partner-img/partner-images/finastra.png"
import Databricks from "../../assets/images/partner-img/partner-images/databricks.png"
import Gcp from "../../assets/images/partner-img/partner-images/google-cloud.png"
import Hashicorp from "../../assets/images/partner-img/partner-images/hashicorp.png"

const MainBanner = () => {
  return (
    <div className="main-banner mb-5">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container pt-md-80">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-5">
                <div className="iot-banner-content">
                  <span>SUCCESS IN DIGITAL ENGINEERING</span>
                  <h1>Accelerate your digital transformation</h1>
                  <p>
                                        Atyeti provides Technology Services that transform and
                                        enhance your Digital ecosystem. 
                                        {/* Our technical experts will
                                        support you with the necessary expertise to maintain and
                                        grow your digital platforms. */}
                                    </p>

                  <a href="#sect-services" className="btn btn-primary">
                    See our services
                  </a>
                </div>
              </div>

              <div className="col-lg-5 d-flex justify-content-center mt-5">
                <div className="about-image-main">
                  <div className="logo-card">
                    <div style={{padding:"1rem"}}>
                    <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                      <img src={Hashicorp} alt=""   />
                      <img src={Gcp} alt=""  />
                      </div>
                      <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
               
                      <img src={Finastra} alt=""  />
                      <img
                        src={Databricks}
                        alt=""
                      />
                  
                      </div>
                    </div>
                  </div>
                  {/* <StaticImage
                                        src="../../assets/images/hero-banner.png"
                                        className="animate__animated animate__fadeInUp animate__delay-0.8s"
                                        alt="Accelerate your digital transformation"
                                        loading="eager"
                                    /> */}
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MainBanner
